
.changeNetworkModal-title {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;

    color: #FFFFFF;

    margin-bottom: 24px;
}

.changeNetworkModal-text {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;

    color: #FFFFFF;

    max-width: 400px;
}