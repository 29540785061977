.poolItem {
    cursor: pointer;
    
    padding: 16px;

    border-radius: 8px;
    background: #1F1F1F;
    border: 2px solid #1F1F1F; 

    &:hover {
        border: 2px solid #FFF;
        background: var(--black, #17171A);
    }

    &-head {
        display: flex;
        align-items: center;

        padding-bottom: 20px;

        border-bottom: 1px solid rgba(255, 255, 255, 0.20);
    }

    &-body {
        padding-top: 16px;

        display: flex;
        align-items: center;
    }

    &-item {
        width: 50%;

        &-label {
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;

            color: rgba(255, 255, 255, 0.80);

            margin-bottom: 8px;
        }

        &-value {
            font-size: 16px;
            font-weight: 500;

            color: var(--white, #FFF);
        }
    }

    &-token {
        display: flex;
        align-items: center;

        font-size: 16px;
        font-weight: 500;

        color: var(--white, #FFF);

        & svg, img {
            width: 32px;
            height: 32px;

            margin-right: 8px;
        }
    }

    &-network {
        display: flex;
        align-items: center;

        font-size: 16px;
        font-weight: 500;

        color: var(--white, #FFF);

        &-icon {
            width: 32px;
            height: 32px;

            margin-right: 8px;

            display: flex;
            justify-content: center;
            align-items: center;

            border-radius: 8px;
            background: #FFF;
        }
        
        & svg, img {
            width: 24px;
            height: 24px;
        }
    }
}