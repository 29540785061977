
.connectWalletModal-wallets {
    height: 70vh;

    overflow: auto;

    padding-right: 24px;
    margin-right: -24px;

    color: #fff;
}

.connectWalletModal-search {
    display: flex;
    align-items: center;

    position: relative;

    width: 100%;
    background: #FFFFFF;
    border-radius: 10px;

    overflow: hidden;

    margin-bottom: 24px;
}

.connectWalletModal-search input {
    width: 100%;
    border: none;

    padding: 20px 24px;

    font-family: 'Poppins';

    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;

    color: rgba(23, 23, 26, 1);
}

.connectWalletModal-search input:placeholder {
    color: rgba(23, 23, 26, 0.2);
}


.connectWalletModal-search svg {
    right: 20px;
    position: absolute;
}

.connectWalletModal-wallets-grid {
    display: grid;
    grid-template-columns: 126px 126px 126px 126px;

    gap: 0px 16px;
}

.connectWalletModal-wallets::-webkit-scrollbar {
    width: 1px;
}
.connectWalletModal-wallets::-webkit-scrollbar-thumb {
    background: rgb(153, 154, 154);
}

.connectWalletModal-wallets::-webkit-scrollbar-track {
    background: transparent;
}

.connectWalletModal-wallet {

    display: flex;
    align-items: center;

    flex-direction: column;

    width: 126px;
    padding: 16px 10px;
    
    background: #262627;
    border-radius: 8px;

    margin-bottom: 16px;

    cursor: pointer;
}

.connectWalletModal-wallet:hover {
    background: #3D3D3E;
}

.connectWalletModal-wallet-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    
    height: 56px;
    width: 56px;
    border-radius: 8px;


    margin-bottom: 8px;
}

.connectWalletModal-wallet-icon svg {
    width: 32px;
    height: 32px;
}

.connectWalletModal-wallet-icon img {
    width: 32px;
    height: 32px;
}

.connectWalletModal-wallet-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;

    text-align: center;

    color: #FFFFFF;
}

.connectWalletModal-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;

    color: #FFFFFF;

    margin-bottom: 24px;
}

.connectWalletModal-subtitle {
    width: 100%;

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    color: #FFFFFF;
    margin-bottom: 16px;
}

.connectWalletModal-type-grid {
    display: flex;
    flex-wrap: wrap;

    margin-bottom: 24px;
}

.connectWalletModal-type {
    display: flex;
    align-items: center;

    cursor: pointer;

    padding: 8px 12px;
    background: #282828;
    border-radius: 8px;

    margin-right: 16px;
}

.connectWalletModal-type_selected {
    background: #3D3D3E;
    border: 1px solid #FFFFFF;
}

.connectWalletModal-type-title {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;

    color: #FFFFFF;
}

.connectWalletModal-type-icon {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 32px;
    height: 32px;
    
    margin-right: 4px;
}

.connectWalletModal-type-icon svg, .connectWalletModal-type-icon img {
    width: 32px;
    height: 32px;
}

@media screen and (max-width: 500px) {
    .connectWalletModal {
        height: 100%;
    }

    .connectWalletModal-wallets {
        height: unset;
    }
    
    .connectWalletModal-wallets .connectWalletModal-wallet:last-child {
        margin-bottom: 100px;
    }
}