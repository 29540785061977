.poolForm {
    border-radius: 16px;
    background: rgba(23, 23, 26, 0.30);

    &-head {
        display: flex;
        align-items: center;
        justify-content: space-between;

        padding: 24px 32px 20px 32px;

        border-bottom: 1px solid rgba(255, 255, 255, 0.40);
    }

    &-title {
        font-size: 20px;
        font-weight: 500;

        color: var(--white, #FFF);
    }

    &-chain {
        display: flex;
        align-items: center;

        font-size: 16px;
        font-weight: 500;

        color: var(--white, #FFF);

        &-icon {
            width: 32px;
            height: 32px;

            display: flex;
            align-items: center;
            justify-content: center;

            border-radius: 8px;
            background: #FFF;

            margin-right: 8px;

            & svg {
                width: 24px;
                height: 24px;
            }
        }
    }

    &-info {
        border-radius: 10px;
        background: rgba(0, 0, 0, 0.30);

        padding: 16px 20px 20px 20px;

        margin-bottom: 32px;

        &-item {
            display: flex;
            justify-content: space-between;
            align-items: center;

            font-size: 16px;
            font-weight: 400;

            color: var(--white, #FFF);

            &:first-child {
                padding-bottom: 16px;

                border-bottom: 1px solid rgba(255, 255, 255, 0.40);
            }

            &:last-child {
                padding-top: 16px;
            }
        }

        &-value {
            display: flex;
            align-items: center;

            font-weight: 500;

            & svg {
                margin-right: 8px;
            }
        }

        &-label {
            display: flex;
            align-items: center;

            & svg {
                margin-left: 8px;
            }
        }
    }

    &-action {
        width: 100%;
        
        & .button {
            width: 100%;
        }
    }

    &-field {
        margin-bottom: 20px;
    }

    &-label {
        display: flex;
        align-items: center;
        justify-content: space-between;

        font-weight: 500;

        color: #FFF;

        margin-bottom: 8px;

        &-title {
            font-style: 18px;
        }

        &-balance {
            font-style: 16px;
        }
    }

    &-tabs {
        margin-bottom: 20px;

        display: flex;
        align-items: center;

        font-size: 22px;
        font-weight: 500;

        color: rgba(255, 255, 255, 0.60);

        &-item {
            cursor: pointer;
            
            &:first-child {
                margin-right: 24px;
            }

            &_selected {
                text-decoration-line: underline;
                color: #FFF;
            }
        }
    }

    &-content {
        padding: 24px 32px 32px 32px;
    }
}