.transferStatusModal {
    padding-top: 24px;

    &_failed {
        & .transferStatusModal {
            &-description {
                opacity: .4;
            }

            &-item {
                background: rgba(255, 255, 255, 0.8);
            }

            &-content {
                position: relative;
                
            }

            &-timer {
                & .button {
                    background: #EAEAEA;
                }
            }
        }
    }

    &-title {
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 36px;

        color: #FFFFFF;

        text-align: center;
    }

    &-description {
        margin-top: 8px;
        margin-bottom: 32px;

        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        text-align: center;

        color: rgba(255, 255, 255, 0.8);
    }

    &-content {
        width: 380px;
        
        margin: 0 auto;
        margin-bottom: 24px;


        display: flex;
        justify-content: space-between;
        align-items: center;

        @media screen and (max-width: 500px) {
            width: 100%;
        }
    }

    &-item {
        width: 40px;
        height: 40px;

        display: flex;
        justify-content: center;
        align-items: center;

        background: rgb(255, 255, 255);
        border-radius: 8px;

        &:not(.transferStatusModal-item_disabled) {
            cursor: pointer;

            &:hover {
                opacity: .8;
            }
        }

        & img, svg {
            width: 28px;
            height: 28px;
        }
    }

    & .progress {
        margin: 0 8px;
        width: calc(100% - 16px - 80px);
    }

    &-timer {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;

        text-align: center;

        color: rgba(255, 255, 255, 0.8);

        & .button {
            width: 380px;
            margin: 0 auto;
            margin-top: 32px;
            
            @media screen and (max-width: 500px) {
                width: 100%;
            }
        }
    }
}