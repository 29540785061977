.selectToken {
    position: relative;

    display: flex;
    align-items: center;

    padding: 14px 16px;

    background: #076975;
    border-radius: 10px;

    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);

    margin-top: 10px;

    @media screen and (max-width: 600px) {
        box-shadow: unset;
    }

    & .selectTokenDropdown {
        overflow: hidden;
        max-height: 0%;
        opacity: 0;
        transform: translateY(-20px);

        transition: all ease-in-out .3s;
    }

    &_show {
        &.selectToken {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }

        & .selectToken {
            &-angle svg {
                transform: rotate(180deg);
            }
        }

        & .selectTokenDropdown {
            max-height: 1000%;
            opacity: 1;
            transform: translateY(0px);
        }
    }

    &-label {
        margin-right: 24px;

        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 26px;

        color: #FFFFFF;
    }

    &-token {
        display: flex;
        align-items: center;
        cursor: pointer;

        &Icon {
            display: flex;

            margin-right: 8px;
        }

        &Name {
            display: flex;

            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 26px;

            text-transform: uppercase;

            color: #FFFFFF;
        }
    }

    &-angle {
        margin-left: 24px;
        display: flex;
        align-items: center;
    }
}

.selectTokenDropdown {
    z-index: 10;
    position: absolute;

    width: 100%;

    left: 0;
    top: 100%;

    padding-top: 8px;
    padding-bottom: 8px;

    background: #076975;

    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;

    border-top: 1px solid rgba(255, 255, 255, 0.4);

    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);

    @media screen and (max-width: 600px) {
        box-shadow: unset;
    }
}

.selectTokenDropdownItem {
    position: relative;

    display: flex;
    align-items: center;

    cursor: pointer;

    padding: 8px 16px;

    &-check {
        position: absolute;
        right: 16px;
    }

    &-name {
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 26px;
        text-transform: uppercase;

        color: #FFFFFF;

    }

    &-icon {
        width: 32px;
        height: 32px;

        margin-right: 8px;
    }
}