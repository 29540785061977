.input {
    width: 100%;

    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;

    color: #17171A;

    padding: 18px 24px;

    background: #FFFFFF;
    border-radius: 10px;

    outline: none;
    border: none;

    &::placeholder {
        color: rgba(23, 23, 26, 0.2);
    }
}