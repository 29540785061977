.home {
    &-transfer {
        margin-bottom: 5px;

        @media screen and (max-width: 1200px) {
            margin-bottom: 10px;
        }

        @media screen and (max-width: 500px) {
            margin-bottom: 80px;
        }
    }

    &-row {
        width: 100%;
        display: flex;
        justify-content: center;
    }
}