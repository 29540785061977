.HotToast {
  transition: .43s all;
}

.HotToast .content {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  text-align: left;
}

.HotToast.close svg {
  display: block;
  
}

.HotToast.close path {
  stroke:#15DA7B;
}

.HotToast div[role='status'] {
  text-align: left !important;
  justify-content: flex-start;
  font-weight: 500;
}