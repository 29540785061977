.socials {
    position: fixed;
    right: 40px;
    bottom: 40px;

    display: flex;
    align-items: center;

    @media screen and (max-width: 1200px) {
        position: unset;
        width: 100%;
        justify-content: center;

        padding: 0px 40px 40px 40px;
    }

    @media screen and (max-width: 1250px) {
        display: none;
    }

    &-item:not(:last-child) {
        margin-right: 48px;
    }
}