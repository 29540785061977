.specialOffer {
    cursor: pointer;
    
    width: 100%;
    height: 60px;

    padding: 0px 16px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    border-radius: 10px;

    border: 1px solid rgba(48, 222, 248, 1);

    background-color: rgba(0, 0, 0, 0.4);

    &-text {
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        
        background: linear-gradient(rgba(89, 255, 235, 1), rgba(20, 199, 255, 1));
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent; 
    }

    & svg {
        width: 32px;
        height: 32px;
    }
}