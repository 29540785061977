.staking {
    display: flex;
    justify-content: center;

    padding-top: 10px;

    & > div {
        margin-right: 32px;
    }

    @media screen and (max-width: 1200px) {
        flex-direction: column;
        align-items: center;
        flex-flow: column-reverse;

        & > div {
            margin-right: unset;
            margin-bottom: 32px;
        }
    }

    @media screen and (max-width: 550px) {
        & .stakingInfo {
            width: unset;
        }
    }
}