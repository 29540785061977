.progress {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    &-content {
        width: 100%;
        height: 4px;

        border-radius: 10px;
        
        background-color: rgba(255, 255, 255, 0.8);

        overflow: hidden;

        &_failed {
            & .progress-filled {
                background: #E94C4C;
            }
        }
    }

    &-close {
        width: 40px;
        height: 24px;
        padding-left: 8px;
        padding-right: 8px;

        & svg {
            width: 24px;
            height: 24px;

            & path {
                stroke: #E94C4C;
            }
        }
    }
    
    &-filled {
        transition: width 1s ease;
        height: 100%;
        width: 0%;
        
        background: linear-gradient(90deg, #59FFEB 0%, #14C7FF 100%);
    }
}