.maintenanceModal {
    padding: 10px 0;
    &-title {
        font-size: 20px;
        font-weight: 500;
        color: #fff;
        text-align: center;

        margin-bottom: 10px;
    }

    &-image {
        width: 100%;

        display: flex;
        justify-content: center;
        align-items: center;

        margin-bottom: 16px;
        position: relative;

        &::before {
            -webkit-animation: pulse 4s cubic-bezier(0,0,0,.5) infinite;
            animation: pulse 4s cubic-bezier(0,0,0,.5) infinite;
            background: linear-gradient(105.39deg,#58feec .63%,#17c9ff 98.61%);
            border-radius: 50%;
            content: "";
            display: block;
            -webkit-filter: blur(40px);
            filter: blur(40px);
            height: 100px;
            left: calc(50% - 50px);
            position: absolute;
            top: calc(50% - 50px);
            width: 100px;
        }

        & img {
            z-index: 1;
            width: 280px;
        }
    }

    &-text {
        text-align: center;

        font-size: 16px;
        color: #fff;

        & b {
            font-size: 18px;
            line-height: 50px;
        }

        & a {
            margin-left: 10px;
        }
    }
}

@keyframes pulse {
  50% { transform: scale(1.6); }
}
