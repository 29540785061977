.settings {
    position: relative;

    display: flex;
    align-items: center;

    & > div:first-child {

        @media screen and (max-width: 500px) {
            margin-right: 0px;
        }
    }

    & > svg {
        margin-right: 18px;
        width: 24px;
        height: 24px;
    }

    &-button {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;

        color: #1E1E1E;

        padding: 10px 16px 9px 16px;

        background-color: #fff;

        border-radius: 10px;
    }
}