.stakingForm {
    width: 550px;
    max-width: 100%;

    border-radius: 16px;
    background: rgba(30, 30, 30, 0.30);

    @media screen and (max-width: 550px) {
        width: 100%;
    }

    &-durationInfo {
        font-size: 16px;
        text-align: center;
        font-style: normal;
        font-weight: 400;

        line-height: 28px;

        color: var(--white, #FFF);

        margin-bottom: 32px;

        @media screen and (max-width: 550px) {
            font-size: 14px;
        }
    }

    &-title {
        padding-top: 24px;
        padding-bottom: 20px;

        text-align: center;

        font-size: 20px;
        font-weight: 500;

        color: #FFF;

        border-bottom: 1px solid rgba(255, 255, 255, 0.40);
    }

    &-content {
        padding: 20px 32px;

        @media screen and (max-width: 550px) {
            padding: 20px;
        }
    }

    &-item {
        margin-bottom: 24px;

        &-row {
            display: flex;
            align-items: center;
            justify-content: space-between;

            margin-bottom: 8px;
        }

        &-balance {
            font-size: 16px;

            color: #FFF;
        }

        &-label {
            font-size: 18px;
            font-weight: 500;

            color: #FFF;       
        }
    }

    &-action {
        & .button {
            width: 100%;
        }
    }

    &-info {
        padding: 4px 24px;
        
        border-radius: 10px;
        background: rgba(0, 0, 0, 0.30);

        margin-bottom: 32px;

        &-value {
            font-weight: 500;

            text-align: right;  

            @media screen and (max-width: 550px) {
                font-size: 14px;
            } 
        }

        &-item {
            display: flex;
            align-items: center;
            justify-content: space-between;

            padding: 16px 0px;
            
            font-size: 16px;

            font-weight: 400;

            color: #FFF;

            &:not(:last-child) {
                border-bottom: 1px solid rgba(255, 255, 255, 0.40);
            }

            @media screen and (max-width: 550px) {
                font-size: 14px;
            }    
        }
    }
}