.modal {
    z-index: 10000;

    position: fixed;
    left: 0;
    top: 0;
    
    width: 100vw;
    height: 100vh;

    background: rgba(0,0,0,.4);

    display: none;
    justify-content: center;
    align-items: center;
}

.modal_show {
    display: flex;
}

.modal-rollUp {
    
}

.modal-content {
    padding: 24px;

    background: #17171A;
    border: 1px solid #17171A;

    border-radius: 10px;

    position: relative;

    min-width: 486px;
    max-width: 650px;
}

.modal-close {
    position: absolute;
    right: 30px;
    top: 24px;
    cursor: pointer;
}

.modal-close path {
    stroke: #fff;
}

.modal_show {
    
}


@media screen and (max-width: 500px) {
    .modal-content {
        width: 100%;
        height: 100%;
        border-radius: 0px;

        min-width: unset;
    }
}