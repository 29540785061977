.duration {
    width: 100%;

    display: flex;

    flex-wrap: wrap;
    
    align-items: center;
    justify-content: space-between;
 
    &Item {
        width: 30%;

        padding: 15px 0px;

        display: flex;
        align-items: center;
        justify-content: center;

        cursor: pointer;

        border-radius: 16px;
        background: #FFF;
        box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.15);

        margin-bottom: 12px;


        font-size: 16px;
        font-weight: 500;

        color: #17171A;

        &_selected {
            background: #17171A;

            color: #FFF;
        }
    }
}