.transferStatus {
    cursor: pointer;
    
    min-width: 150px;
    height: 50px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    background: #232530;

    border: 1px solid #2F343E;
    border-radius: 10px;

    padding: 9px 8px 7px 12px;

    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;

    color: #FFFFFF;

    @media screen and (max-width: 1250px) {
        font-size: 12px;
        padding: 6px 12px;
        height: 36px;
        min-width: unset;
        border-radius: 6px;
    }

    @media screen and (max-width: 650px) {
        max-width: 72px;
        margin-right: 16px;
    }

    &-count {
        @media screen and (max-width: 650px) {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }

    &-container {
    }

    & > svg {    
        width: 28px;
        height: 28px;

        @media screen and (max-width: 500px) {
            max-width: 24px;
            min-width: 24px;
        }
    }

    & > div {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;

        color: #FFFFFF;
    }

    &-dropdown {
        overflow: hidden;

        top: calc(100% + 12px);
        overflow: hidden;

        position: absolute;
        background: #232530;
        border-radius: 10px;

        max-height: 0%;
        width: 400px;
        transform: translateY(-20px);
        transition: all ease-in-out .3s;
        opacity: 0;
        z-index: 1;

        @media screen and (max-width: 900px) {
            right: 0px;
        }

        @media screen and (max-width: 500px) {
            position: absolute;
            right: 0px;
            width: calc(100vw - 64px);
        }

        @media screen and (max-width: 375px) {
            position: absolute;
            right: -16px;
            width: calc(100vw - 32px);
        }

        &_show {
            transform: translateY(0px);
            max-height: 1000%;
            opacity: 1;
        }
    }
}

.transferStatusItem {
    width: 100%;
    padding: 12px 20px 12px 20px;
    cursor: pointer;

    &_failed {
        & .transferStatusItem {
            &-title {
                opacity: .4;
            }

            &-timer {
                color: #E94C4C;
            }
        }
    }

    &_complete {
        & .transferStatusItem-timer {
            background: linear-gradient(90deg, #59FFEB 0%, #14C7FF 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent;
        }
    }

    &:hover {
        background: #323543;
    }

    &:first-child {
        padding-top: 20px;
    }

    &:last-child {
        padding-bottom: 20px;
    }

    &-network {
        text-transform: capitalize;
    }

    &-header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        margin-bottom: 14px;
    }

    &-title {        
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;

        color: #FFFFFF;
    }
    
    &-link {
        margin-bottom: 4px;

        display: flex;

        cursor: pointer;

        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        line-height: 15px;

        color: rgba(255, 255, 255, 0.6);

        &:hover {
            color: #fff;

            & svg path {
                stroke-opacity: 1;
            }
        }
    }

    &-descr {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }

    &-timer {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;

        text-align: right;

        color: #FFFFFF;
    }
}