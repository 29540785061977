.pageLayout {
    &-background {
        position: fixed;
        width: 1233px;
        height: 1233px;
        left: calc(50vw - 605px);
        top: calc(50vh - 605px);
        z-index: -1;
        border-radius: 50%;
        
        background: radial-gradient(73.51% 73.51% at 82.14% 15.92%, #00FFC2 19.79%, #009FFF 100%);
        filter: blur(325px);
    }
}