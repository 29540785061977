.transferInput {
    display: flex;
    justify-content: space-between;

    background: #FFFFFF;
    border-radius: 10px;

    position: relative;
}

.transferInput-left {
    padding: 24px;
    border-right: 1px solid #C2C2C2;

    display: flex;
    align-items: center;
}

.transferInput-content {
    display: flex;
    align-items: flex-end;
    align-items: center;

    padding: 14px 24px 10px 16px;
}

.transferInput-tooltips {
    display: flex;
}

.transferInput-tooltips .tooltip:not(:last-child) {
    margin-right: 8px;
}

.transferInput-token {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;

    text-align: right;

    color: rgba(23, 23, 26, 0.2);

    margin-right: 1px;
}

.transferInput-field {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;

    width: 100%;

    text-align: right;
    border: none;
    outline: none;

    background-color: #fff;
}

.transferInput-field_error {
    color: #E94C4C;
}

.transferInput-field::placeholder {
    color: rgba(23, 23, 26, 0.2);
}

.transferInput-field:disabled {
    color: #000;
    background-color: #fff;
}

@media screen and (max-width: 1240px) {
    .transferInput-left {
        padding: 24px;
    }

    .transferInput-content {
        padding-right: 15px;
    }
}

@media screen and (max-width: 500px) {
    .transferInput-left {
        padding: 24px;
    }
    .transferInput-tooltips {
        display: none;
    }
}