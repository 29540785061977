.transferLoaderModal {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    padding-bottom: 16px;
    padding-top: 11px;
}

.transferLoaderModal-title {
    z-index: 10;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 26px;

    color: #FFFFFF;

    margin-bottom: 16px;
}

.transferLoaderModal-camel {
    text-transform: capitalize;
}

.transferLoaderModal-text {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;

    color: rgba(255, 255, 255, 0.8);
}

.transferLoaderModal-icon svg {
    width: 100px;
    height: 100px;
    margin-bottom: 16px;
}