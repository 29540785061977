.transferForm {
    width: 550px;
    max-width: 100%;
    
    margin-top: 32px;
    padding: 32px;

    background: rgba(30, 30, 30, 0.3);
    border-radius: 25px;

    @media screen and (max-width: 600px) {
        background: unset;
        padding: 20px;
    }

    &-from {
        display: flex;
        align-items: center;
        justify-content: space-between;

        margin-bottom: 8px;

        & div {
            display: flex;
            align-items: center;
        }
    }

    &-balance {
        color: hsla(0,0%,100%,.8);
        font-size: 14px;
        font-weight: 400;
        -webkit-user-select: none;
        user-select: none;
        white-space: nowrap;
    }

    &-max {
        color: #59ffeb;
        cursor: pointer;
        font-weight: 600;
        margin-left: 8px;
        text-transform: uppercase;
        -webkit-user-select: none;
        user-select: none;
    }

    &-to {
        display: flex;
        justify-content: space-between;
        align-items: center;

        margin-bottom: 9px;
    }

    &-fee {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;

        color: rgba(255, 255, 255, 0.8);
    }

    &-wallet {
        margin-left: 12px;

        color: hsla(0,0%,100%,.8);
        font-size: 14px;
        font-weight: 400;

        @media screen and (max-width: 500px) {
            display: none!important;
        }

        & svg, img {
            width: 16px;
            height: 16px;
            margin-right: 6px;
        }
    }

    &-swap {
        width: 100%;

        display: flex;
        align-items: center;
        justify-content: center;

        margin-top: 16px;
        margin-bottom: -4px;
    }

    &-label {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;

        color: #FFFFFF;

        &_recipient {
            margin-bottom: 8px;
        }
    }

    & .transferFormFee {
        margin-top: 16px;
        margin-bottom: 24px;
    }

    & .transferFormWarning {
        margin-bottom: 32px;
    }

    &-action {
        width: 100%;
        margin-top: 32px;

        @media screen and (max-width: 600px) {
            position: fixed;
            bottom: 40px;
            left: 0px;
            margin: 0 auto;
            padding: 0px 20px;
        }

        & .button {
            width: 100%;

            & div {
                text-transform: uppercase;
            }
        }
    }
}