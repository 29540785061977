.wallet {
    display: flex;
    align-items: center;
    justify-content: space-between;
    
    background-color: #fff;
    
    border-radius: 10px;
    padding: 10px 12px;
    
    min-width: 265px;

    cursor: pointer;

    &-left {
        display: flex;
        align-items: center;
        
    }

    @media screen and (max-width: 1250px) {
        min-width: unset;
        padding: 3px 12px 3px 5px;
        border-radius: 6px;
    }

    &-wrapper {
        position: relative;
    }

    &-angle {
        width: 24px;
        height: 24px;
        margin-left: 3px;

        &_show {
            transform: rotate(180deg);
        }

        @media screen and (max-width: 1250px) {
            display: none;
        }
    }

    &-text {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;

        color: #1E1E1E;
    }

    &-history {
        cursor: pointer;
        
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;

        color: #1E1E1E;

        padding: 12px 0px;

        border-top: 1px solid #EAEAEA;

        display: flex;
        align-items: center;
        justify-content: space-between;

    }

    &-icon {
        display: flex;
        align-items: center;
        position: relative;
        margin-right: 20px;

        &-item {
            &:first-child {
                z-index: 10;
            }

            width: 30px;
            height: 30px;

            overflow: hidden;

            display: flex;
            justify-content: center;
            align-items: center;

            border-radius: 50%;
            background-color: #fff;
        }

        @media screen and (max-width: 900px) {
            margin-right: 6px;
        }

        &_full {
            margin-right: 35px;

            @media screen and (max-width: 900px) {
                margin-right: 32px;
            }
        }

        & > :nth-child(1) {
            z-index: 0;
        }

        & > :nth-child(2) {
            position: absolute;
            right: -15px;
            z-index: 1;
        }

        & > :nth-child(3) {
            position: absolute;
            right: -30px;
            z-index: 2;
        }

        & img {
            width: 24px;
            height: 24px;

            border-radius: 50%;

            @media screen and (max-width: 900px) {
                width: 24px;
                height: 24px;
            }
        }

        & svg {
            width: 24px;
            height: 24px;

            @media screen and (max-width: 900px) {
                width: 24px;
                height: 24px;
            }
        }
    }

    &-button {
        padding: 10px 16px 9px 16px;
        background: #FFFFFF;
        
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 21px;

        color: #1E1E1E;

        border-radius: 10px;
        cursor: pointer;

        @media screen and (max-width: 800px) {
            padding: 9px 12px;
            font-size: 12px;
            line-height: 18px;
            max-height: 36px;
        }
    }

    &-dropdown {
        z-index: 11;

        position: absolute;
        top: calc(100% + 12px);
        width: 265px;
        opacity: 0;
        max-height: 0;
        overflow: auto;
        transform: translateY(-20px);

        transition: all .5s ease;

        background-color: #fff;
        
        // padding: 0px 12px;
        
        border-radius: 10px;

        box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.2);

        @media screen and (max-width: 900px) {
            right: 0px;
        }

        &_show {
            max-height: 395px;
            opacity: 1;
            transform: translateY(0px);
        }
    }

    &-addWallet {
        width: 100%;
        
        display: flex;
        justify-content: center;
        align-items: center;

        padding: 20px;
        padding-bottom: 20px;
    }
}

.dropdownItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 16px;

    &-row {
        display: flex;
        align-items: center;
    }

    &-icon {
        width: 24px;
        height: 24px;
        margin-right: 4px;

        & svg, img {
            width: 24px;
            height: 24px;
        }
    }

    &-address {
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 18px;

        color: #1E1E1E;

        margin-right: 14px;
    }

    &-action {
        cursor: pointer;

        width: 32px;
        height: 32px;

        display: flex;
        justify-content: center;
        align-items: center;

        background: #E8E8E8;
        border-radius: 8px;

        &:not(:last-child) {
            margin-right: 8px;
        }
    }
}