.header {
    padding: 20px 32px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: 550px) {
        flex-direction: column;

        padding: 20px 16px;
    }

    & .veMoverBalance {
        margin-right: 16px;
    }

    &-logo {
        width: 20%;

        & a {
            display: flex;
            justify-content: start;
        }

        @media screen and (max-width: 650px) {
            max-width: 34px;
            overflow: hidden;

            & svg {
                min-width: 125px;
            }
        }

        @media screen and (max-width: 550px) {
            max-width: unset;
            margin-bottom: 24px;
        }
    }

    
    & .transferStatus {
        position: absolute;

        right: 0px;

        top: calc(100% + 16px);

        @media screen and (max-width: 650px) {
            position: relative;
        }
    }

    &-settings {
        width: 20%;

        position: relative;
        
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    &-navigation {
        width: 35%;
        
        display: flex;
        justify-content: center;
    }

    &-button {
        cursor: pointer;
        
        display: none;

        width: 36px;
        height: 36px;

        justify-content: center;
        align-items: center;

        background-color: #fff;
        border-radius: 6px;

        margin-left: 16px;

        & svg {
            width: 24px;
            height: 24px;
        }
    }

    @media screen and (max-width: 1250px) {
        .header {
            &-navigation {
                display: none;
            }

            &-button {
                display: flex;
            }

            &-logo {
                width: unset;
            }

            &-settings {
                width: unset;

                display: flex;
                align-items: center;
            }
        }
        
    }

    @media screen and (max-width: 375px) {
        .header {
            &-settings {
                width: 100%;
                justify-content: center;
            }
        }
    }
}