.poolList {
    &-title {
        font-size: 48px;
        font-weight: 500;

        color: #fff;

        margin-bottom: 20px;
    }

    &-subtitle {
        font-size: 18px;
        font-weight: 400;

        color: #fff;

        margin-bottom: 40px;
    }

    &-content {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        & .poolItem {
            width: 320px;

            &:not(:last-child) {
                margin-right: 32px;
            }
        }
    }
}