.transactionHistory {
    &-angle {
        padding: 16px 0px;

        cursor: pointer;
        
        display: flex;
        justify-content: center;
        align-items: center;

        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;

        color: #4F4F4F;

        & svg {
            margin-left: 8px;
        }
        
        &_rotate {
            & svg {
                transform: rotate(180deg);
            }
        }
    }
}

.transactionHistoryItem {
    width: 100%;
    padding: 8px 16px;

    &-header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        margin-bottom: 12px;

    }

    &-token {
        width: 45%;

        display: flex;
        align-items: center;

        &_hover {
            &:hover {
                cursor: pointer;

                & .transactionHistoryItem-amount {
                    background: linear-gradient(90deg, #0F5DD9 0%, #13B7A8 100%);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    background-clip: text;
                    text-fill-color: transparent; 
                }
            }
        }
    }

    &-icon {
        display: flex;
        position: relative;

        & svg {
            width: 24px;
            height: 24px;
        }
    }

    &-status {
        display: flex;
        margin-left: 12px;
        margin-right: 12px;

        &_status {
            &_FAIL {
                & path {
                    stroke: rgba(233, 76, 76, 1);
                }
            }

            &_NEW {
                & circle {
                    stroke: #000;
                }
            }

            &_IN_PROGRESS {
                & circle {
                    stroke: #000;
                }
            }

            &_AWAITING_LIQUIDITY {
                & circle {
                    stroke: #000;
                }
            }
        }

        & svg {
            width: 16px;
            height: 16px;
        }
    }

    &-network {
        position: absolute;
        right: -6px;
        bottom: -6px;

        width: 18px;
        height: 18px;

        background-color: #fff;
        border-radius: 50%;

        display: flex;
        justify-content: center;
        align-items: center;


        & svg {
            width: 16px;
            height: 16px;
        }

        & img {
            width: 16px;
            height: 16px;
        }
    }

    &-amount {
        margin-left: 8px;

        font-style: normal;
        font-weight: 600;
        font-size: 11px;
        line-height: 16px;
        /* identical to box height */

        display: flex;
        align-items: center;

        color: #17171A;
    }

    &-description {
        display: flex;
        align-items: center;
        justify-content: space-between;

        font-style: normal;
        font-weight: 500;
        font-size: 10px;
        line-height: 15px;

        color: #4F4F4F;
    }
}