.pool {
    max-width: 1328px;
    margin: 0 auto;

    &-form {
        max-width: 550px;
        margin: 0 auto;
    }

    &-back {
        cursor: pointer;
        
        display: flex;
        align-items: center;

        font-size: 18px;
        font-weight: 500;

        color: #FFF;

        margin-bottom: 16px;
    }
}