.stakingInfo {
    width: 550px;
    max-width: 100%;

    border-radius: 16px;
    background: rgba(30, 30, 30, 0.30);

    &-info {
        display: flex;
        align-items: center;
        justify-content: space-between;

        font-size: 16px;
        font-weight: 400;
        color: #FFF;

        &-value {
            font-weight: 500;
        }

        &:not(:last-child) {
            padding-bottom: 20px;

            border-bottom: 1px solid rgba(255, 255, 255, 0.40);
        }

        &:not(:first-child) {
            padding-top: 20px;
        }
    }

    &-item {
        padding: 24px;

        &:not(:last-child) {
            border-bottom: 1px solid rgba(255, 255, 255, 0.40);
        }

        &_flex {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }

    &-content {
        padding: 20px;

        border-radius: 10px;
        background: rgba(0, 0, 0, 0.30);

        margin-bottom: 16px;

        & .stakingInfo-title {
            margin-bottom: 20px;
        }
    }

    &-list {
        font-size: 16px;
        font-weight: 400;

        color: #FFF;

        &-item {
            display: flex;
            
            & > div {
                display: flex;
                justify-content: flex-end;

                min-width: 18px;

                margin-right: 6px;
            }

            &:not(:last-child) {
                margin-bottom: 4px;
            }
        }
    }

    &-title {
        font-size: 20px;
        font-weight: 500;

        color: #FFF;
    }

    &-text {
        font-size: 16px;
        font-weight: 400;
        line-height: 28px;

        color: var(--white, #FFF);

        margin-bottom: 16px;
    }


    &-action {
        cursor: pointer;
        
        width: 100%;
        height: 60px;

        padding: 0px 16px;

        display: flex;
        align-items: center;
        justify-content: space-between;

        border-radius: 10px;

        border: 1px solid rgba(48, 222, 248, 1);

        background-color: rgba(0, 0, 0, 0.4);

        &-text {
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
            
            background: linear-gradient(rgba(89, 255, 235, 1), rgba(20, 199, 255, 1));
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent; 
        }

        & svg {
            width: 32px;
            height: 32px;
        }
    }
}