.inputTokenSelect {
    position: relative;

    & .inputTokenSelect-dropdown {
        overflow: hidden;

        max-height: 0%;
        opacity: 0;

        transition: all ease-in-out .3s;
    }

    &-itemLeftWrapper {
        display: flex;
        align-items: center;
    }

    &_show {
        & .inputTokenSelect {
            &-itemAngle {
                transform: rotate(180deg);
            }
        }

        & .inputTokenSelect-dropdown {
            max-height: 10000%;
            opacity: 1;
        }
    }

    &-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        
        width: 164px;

        cursor: pointer;

        @media screen and (max-width: 500px) {
            width: 110px;
        }

        &Icon {
            width: 32px;
            height: 32px;
            margin-right: 9px;

            & img {
                width: 32px;
                height: 32px;
            }

            & svg {
                width: 32px;
                height: 32px;
            }
        }

        &Title {
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 27px;

            color: #17171A;
        }

        &Angle {
            width: 24px;
            height: 24px;
        }
    }

    &-dropdown {
        position: absolute;
        z-index: 1;

        left: -24px;
        top: calc(100% + 32px);
        box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.25);
    }
}

.inputTokenSelectDropdown {
    z-index: 10;
    width: 212px;

    background: #FFFFFF;
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    padding: 8px 0;
}

.inputTokenSelectDropdownItem {
    width: 100%;
    
    padding: 8px 16px;

    display: flex;
    align-items: center;

    cursor: pointer;

    &:hover {
        background: #AFE2FF;
    }

    &-icon {
        margin-right: 8px;
        width: 32px;
        height: 32px;

        & svg {
            width: 32px;
            height: 32px;
        }

        & img {
            width: 32px;
            height: 32px;
        }
    }

    &-name {
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;

        color: #17171A;
    }
}