.swap {
    background: rgba(0, 0, 0, 0.2);
    border-radius: 6px;

    padding: 4px;

    &-angle {
        width: 24px;
        height: 24px;
        cursor: pointer;

        &_rotate {
            margin-bottom: -8px;
            transform: rotate(180deg);
        }
    }
}