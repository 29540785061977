.button {
    cursor: pointer;

    font-family: 'Poppins';
    font-style: normal;

    border: none;

    display: flex;
    justify-content: center;
    align-items: center;

    outline: none;

    & svg {
        margin-left: 8px;
    }

    &_view {
        &_gradient {
            color: #17171A;

            background: linear-gradient(90deg, #59FFEB 0%, #14C7FF 100%);
        }

        &_white {
            color: rgba(30, 30, 30, 1);

            background: rgba(255, 255, 255, 1);
        }
    }

    &_size {
        &_s {
            font-weight: 500;
            font-size: 12px;
            line-height: 18px;
            
            padding: 7px 18px;
            border-radius: 8px;
        }

        &_m {
            font-weight: 500;
            font-size: 18px;
            line-height: 27px;

            padding-top: 16px;
            padding-bottom: 17px;
            border-radius: 10px;
        }
    }

    &:disabled {
        background: #EAEAEA;
        cursor: unset;
    }
}