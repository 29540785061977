.transferFormFee {

    &-fee {
        display: flex;
        align-items: center;
    }

    &-line {
        text-decoration: line-through;
        opacity: .4;
        margin-right: 8px;
    }

    &-discount {
        padding-top: 2px;
        padding-left: 4px;
        padding-right: 4px;
        padding-bottom: 1px;
        
        border-radius: 4px;
        background: linear-gradient(92deg, #2137FC 0%, #1DAEFF 100%);

        color: #FFF;
        font-size: 12px;
        font-weight: 500;

        margin-left: 8px;
    }

    &-label {
        cursor: pointer;

        display: flex;
        align-items: center;

        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;

        color: rgba(255, 255, 255, 0.8);

        & svg {
            margin-left: 4px;
        }
    }

    &-text {
        display: flex;
        align-items: center;
        justify-content: space-between;
        
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;

        color: rgba(255, 255, 255, 0.8);

        &:not(:last-child) {
            margin-bottom: 4px;
        }
    }

    &-icon {
        &_show {
            transform: rotate(180deg);
        }
    }

    &-content {
        padding: 12px 16px;
    }

    &-holder {
        padding: 12px 16px;
        border-top: 1px solid rgba(255, 255, 255, 0.40);

        color: #FFF;
        font-size: 14px;
        line-height: 20px;

    }

    &-dropdown {
        margin-top: 8px;

        background: rgba(0, 0, 0, 0.3);
        border-radius: 10px;

        max-height: 0px;
        overflow: hidden;

        transition: max-height .5s ease;

        &_show {
            max-height: 200px;
        }
    }
}