.specialOfferModal {
    width: 600px;

    display: flex;
    justify-content: space-between;

    padding-top: 24px;
    padding-bottom: 24px;

    &-image {
        position: relative;

        display: flex;
        align-items: center;
        justify-content: center;

        & img {
            z-index: 1;
        }
        
        &-background {
            z-index: 0;
            position: absolute;

            width: 108px;
            height: 109px;

            border-radius: 109px;
            background: rgba(22, 171, 255, 0.90);
            filter: blur(60px);
        }
    }

    &-title {
        text-align: center;
        font-size: 24px;
        font-weight: 500;
        color: #FFF;

        margin-bottom: 32px;
    }

    &-list {
        &-item {
            display: flex;
            
            color: rgba(255, 255, 255, 0.80);
            font-size: 16px;

            margin-bottom: 16px;

            & div:first-child {
                width: 20px;
                text-align: center;
                margin-right: 5px;
            }
        }
    }

    &-action {
        margin-top: 20px;
        width: 100%;

        & .button {
            width: 100%;
        }
    }

    &-condition {
        padding: 12px 16px;

        border-radius: 8px;
        border: 1px solid rgba(255, 255, 255, 0.10);
        background: #262627;

        &-item {
            display: flex;
            
            position: relative;

            font-size: 13px;
            color: #FFF;

            padding-left: 10px;

            &:not(:last-child) {
                margin-bottom: 12px;
            }

            &::before {
                content: "";

                position: absolute;

                left: 0;
                top: 8px;

                width: 3px;
                height: 3px;
                
                border-radius: 50%;

                background-color: #fff;
            }
        }
    }

    &-item {
        width: 50%;

        &:first-child {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            border-right: 1px solid rgba(255, 255, 255, 0.2);
        }

        &:last-child {
            padding-left: 16px;
        }
    }
}