.logo {
    @media screen and (max-width: 650px) {
        & path:not(:last-child) {
            display: none;
        }
    }

    @media screen and (max-width: 550px) {
        & path:not(:last-child) {
            display: block;
        }
    }
}