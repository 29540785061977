.changeEvmNetworkModal {
    margin-bottom: -16px;
}

.changeEvmNetworkModal-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;

    color: #FFFFFF;

    margin-bottom: 24px;
}

.changeEvmNetworkModal-text {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;

    color: #FFFFFF;
}

.changeEvmNetworkModal-networks {
    margin-top: 16px;
    
    display: flex;
    flex-wrap: wrap;
}