.veMoverBalance {
    max-height: 40px;
    
    padding: 4px;
    padding-left: 16px;

    display: flex;
    align-items: center;

    border-radius: 24px;
    background: #323232;

    &-balance {
        font-size: 14px;
        font-weight: 500;

        color: #fff;

        margin-right: 9px;
    }

    &-logo {
        padding: 4px 8px;
        display: flex;
        align-items: center;

        border-radius: 24px;
        background: #17171A;

        font-size: 13px;
        font-weight: 500;
        color: #FFF;

        & svg {
            margin-right: 4px;
        }
    }
}