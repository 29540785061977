.navigation {
    display: flex;

    &-item {
        cursor: pointer;
        position: relative;

        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 28px;

        text-decoration: none;

        color: #ffffff;

        z-index: 1000;

        &:not(:last-child) {
            margin-right: 24px;
        }

        &:hover {
            opacity: 0.8;
        }

        &_disabled:hover {
            &::before {
                content: "Coming soon";
                width: 132px;
                height: 37px;

                position: absolute;
                top: calc(100% + 5px);
                left: -50%;

                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 3px;

                color: #fff;
                background: #222;
                font-size: 13px;

                border: 1px solid white;
            }

            &::after {
                content: "";

                position: absolute;
                border-left: 8px solid transparent;
                border-right: 8px solid transparent;
                border-bottom: 8px solid white;
                top: 100%;
                left: 50%;
                margin-left: -8px;
                border-bottom-color: #222;
                border-bottom-style: solid;
                border-bottom-width: 6px;
            }
        }
    }
}
