.mobileMenu {
    position: fixed;
    left: 0;
    top: 0;

    width: 100vw;
    height: 100vh;

    justify-content: center;
    align-items: center;
    flex-direction: column;

    background-size: cover;

    z-index: 1000;

    display: none;
    background: #222125;

    &-navigation {
        margin-bottom: 32px;
    }

    &-background {
        position: fixed;
        width: 1233px;
        height: 1233px;
        left: calc(50vw - 605px);
        top: calc(50vh - 605px);
        z-index: -1;
        border-radius: 50%;
        
        background: radial-gradient(73.51% 73.51% at 82.14% 15.92%, #00FFC2 19.79%, #009FFF 100%);
        filter: blur(325px);
    }

    &_show {
        display: flex;
    }

    &-row a:not(:last-child) {
        margin-right: 46px;
    }

    &-close {
        position: absolute;

        right: 24px;
        top: 24px;

        cursor: pointer;

        & svg {
            fill: #fff;
            width: 32px;
            height: 32px;

            & path {
                stroke: #fff;
            }
        }
    }
}